<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="other-trainee-trainer-registration-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tpm.other_trainee_trainer_registration') }} {{ $t('globalTrans.list') }}</router-link>
            <b-button variant="primary" @click="pdfExport" class="">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                  <b-col>
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="courses.office_id">
                          {{ $t('elearning_tpm.other_trainee_trainer_registration') + ' ' + $t('globalTrans.details') }}
                      </list-report-head>
                  </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ courses.circular_memo_no }}</td>
                        <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 3%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                        <td style="width: 32%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? courses.org_bn : courses.org }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.office_type') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.office_type_bn : courses.office_type }}</td>
                        <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 3%">:</th>
                        <td style="width: 32%" class="text-left">{{ $i18n.locale === 'bn' ? courses.fiscal_year_bn : courses.fiscal_year }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('globalTrans.office') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.office_bn : courses.office }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.training_type_bn : courses.training_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.training_category_bn : courses.training_category }}</td>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.training_title_bn : courses.training_title }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('elearning_tpm.trainee_type') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 82%" colspan="4">{{ getOtherTrainee(formData.other_trainee_type_id) }}</td>
                      </tr>
                      <!-- <tr>
                        <th style="width: 15%"  class="text-left">{{ $t('globalTrans.course') }}</th>
                        <th style="width: 3%" class="text-center">:</th>
                        <td style="width: 32%">{{ $i18n.locale === 'bn' ? courses.course_name_bn : courses.course_name }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_tpm.course_module') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? courses.course_module_bn : courses.course_module }}</td>
                      </tr> -->
                      <!-- <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_tpm.course_module_details') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? courses.course_module_details_bn : courses.course_module_details }}</td>
                        <th style="width: 15%"  class="text-right"></th>
                        <th style="width: 5%" class="text-center"></th>
                        <td style="width: 30%"></td>
                      </tr> -->
                      <!-- <tr>
                        <th style="width: 15%"  class="text-right">{{$t('globalTrans.attachment')}}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">
                          <b v-if="courses.attachment">
                            (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + courses.attachment" download>{{$t('globalTrans.view_download')}}</a> )
                          </b>
                        </td>
                        <th style="width: 15%"  class="text-right"></th>
                        <th style="width: 5%" class="text-center"></th>
                        <td style="width: 30%"></td>
                      </tr> -->
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.trainee') + ' ' + $t('globalTrans.details')}}</legend>
                      <table class="table" style="width:100%">
                        <thead class="thead">
                          <tr>
                            <th style="width:7%">{{ $t('globalTrans.sl_no')}}</th>
                            <th style="width:15%">{{$t('globalTrans.name')}}<br/>{{$t('admission_form.mobile_no')}}</th>
                            <th style="width:12%">{{$t('elearning_tim.name_of_father_husband')}}</th>
                            <th style="width:12%">{{$t('globalTrans.organization')}}</th>
                            <th style="width:12%">{{$t('globalTrans.profession')}}</th>
                            <th style="width:13%">{{$t('elearning_accommodation.address')}}</th>
                            <th style="width:12%">{{$t('elearning_tim.monthly_income')}}</th>
                            <th style="width:12%">{{$t('globalTrans.age')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(trainee, index) in trainees" :key="index">
                          <td>
                            {{ $n(index + 1) }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainee.p_name_bn : trainee.p_name }}
                            <br/>
                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(trainee.mobile, { useGrouping: false }) }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainee.name_of_father_husband_bn : trainee.name_of_father_husband }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainee.other_org_bn : trainee.other_org }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainee.other_designation_bn : trainee.designation }}
                          </td>
                          <td>
                            {{ trainee.other_address }}
                          </td>
                          <td>
                            {{ $n(trainee.monthly_income) }}
                          </td>
                          <!-- <td>
                            {{ $n(ageCalculation(trainee.date_of_birth)) }}
                          </td> -->
                          <td>
                            <slot v-if="parseInt(trainee.age) > 0">
                              {{ $n(trainee.age) }}
                            </slot>
                            <slot v-else>
                              {{ $n(ageCalculation(trainee.date_of_birth)) }}
                            </slot>
                          </td>
                        </tr>
                        <template v-if="trainees.length === 0">
                          <tr>
                            <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
              <b-overlay :show="load">
                <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_config.trainer') + ' ' + $t('globalTrans.details')}}</legend>
                      <table class="table" style="width:100%">
                        <thead class="thead">
                          <tr>
                            <th style="width:7%">{{ $t('globalTrans.sl_no')}}</th>
                            <th style="width:15%">{{$t('globalTrans.name')}}<br/>{{$t('admission_form.mobile_no')}}</th>
                            <th style="width:12%">{{$t('elearning_tim.name_of_father_husband')}}</th>
                            <th style="width:12%">{{$t('globalTrans.organization')}}</th>
                            <th style="width:12%">{{$t('globalTrans.designation')}}</th>
                            <th style="width:13%">{{$t('elearning_accommodation.address')}}</th>
                            <!-- <th style="width:12%">{{$t('admission_form.age')}}</th> -->
                            <th style="width:12%">{{$t('globalTrans.age')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(trainer, index) in trainers" :key="index">
                          <td>
                            {{ $n(index + 1) }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainer.p_name_bn : trainer.p_name }}
                            <br/>
                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(trainer.mobile, { useGrouping: false }) }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainer.name_of_father_husband_bn : trainer.name_of_father_husband }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainer.other_org_bn : trainer.other_org }}
                          </td>
                          <td>
                            {{ $i18n.locale === 'bn' ? trainer.other_designation_bn : trainer.designation }}
                          </td>
                          <td>
                            {{ trainer.other_address }}
                          </td>
                          <!-- <td>
                            {{ $n(ageCalculation(trainer.date_of_birth)) }}
                          </td> -->
                          <td>
                            <slot v-if="parseInt(trainer.age) > 0">
                              {{ $n(trainer.age) }}
                            </slot>
                            <slot v-else>
                              {{ $n(ageCalculation(trainer.date_of_birth)) }}
                            </slot>
                          </td>
                        </tr>
                        <template v-if="trainers.length === 0">
                          <tr>
                            <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
              </b-overlay>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { traineeTrainerOtherDetails } from '../../api/routes'

export default {
    components: {
        ListReportHead
    },
  created () {
    if (this.$route.query.circilarMemoNo && this.$route.query.other_trainee_type_id) {
      this.formData.other_trainee_type_id = this.$route.query.other_trainee_type_id
      this.getCircularMemoNo(this.$route.query.circilarMemoNo, this.$route.query.other_trainee_type_id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        other_trainee_type_id: 0,
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      load: false,
      officeTypeList: [],
      courses: [],
      items: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    getOtherTrainee (id) {
      const office = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async getCircularMemoNo (circularMemoNo, otherTraineeId) {
      if (circularMemoNo && otherTraineeId) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo,
            other_trainee_type_id: this.$route.query.other_trainee_type_id
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, traineeTrainerOtherDetails, circularMemoNo)
        if (!result.success) {
          this.courses = []
        } else {
            this.courses = result.data[0]
            this.getCustomDataList(result.data[0])
            const trainee = result.data.filter(item => item.registration_for === 2)
            this.trainees = trainee
            const trainer = result.data.filter(item => item.registration_for === 1)
            this.trainers = trainer
        }
        this.load = false
      }
    },
    ageCalculation (dateofBirth) {
      const today = new Date()
      const birthDate = new Date(dateofBirth)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
      }
      return age
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.other_trainee_trainer_registration') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.courses, this, this.trainees, this.trainers)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    },
    getCustomDataList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.courses.org = orgObj.text_en
            this.courses.org_bn = orgObj.text_bn
          } else {
            this.courses.org = ''
            this.courses.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.courses.office = officeObj.text_en
            this.courses.office_bn = officeObj.text_bn
          } else {
            this.courses.office = ''
            this.courses.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.courses.fiscal_year = fiscalYearObj.text_en
            this.courses.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.courses.fiscal_year = ''
            this.courses.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.courses.training_title = trainingTitleObj.text_en
            this.courses.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.courses.training_title = ''
            this.courses.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.courses.office_type = OfficeTypeListObj.text_en
            this.courses.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.courses.office_type = ''
            this.courses.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.courses.training_type = trainingTypeObj.text_en
            this.courses.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.courses.training_type = ''
            this.courses.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.courses.training_category = trainingCategoryObj.text_en
            this.courses.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.courses.training_category = ''
            this.courses.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
